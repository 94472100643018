import React from 'react'

const ListItem = ({ title = '', subtitle = '', onClick = () => { }, iconRight = null, className = '', selectedItem= '' }) => {
  return (
    <div
      className={`flex items-center cursor-pointer px-4 py-2 text-xl font-normal ${className}`}
      onClick={() => onClick()}
    >
      {title}
    </div>
  )
}

export default ListItem