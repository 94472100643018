import React from 'react'
import '../css/modal.css'
import ListItem from './ListItem'

function Modal({ showModal = false, setShowModal = () => { }, childrenClass = '', children = null }) {
  if (!showModal || !children) {
    return null
  }
  return (
    <div
      className='modal'
      onClick={() => setShowModal(!showModal)}
    >
      <div className="modal-content container-data">
        <ListItem title={<span className="close ">&times;</span>} className='justify-end ' />
        {children}
      </div>
    </div>
  )
}


export default Modal
