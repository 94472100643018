import React, { useState, useEffect, Fragment, useContext } from "react";
import axios from "axios";
import { BsTelephoneFill } from "react-icons/bs";
import { MdMail } from "react-icons/md"
import { RiFileCopy2Fill } from "react-icons/ri"
import { logDOM } from "@testing-library/react";


import { FormatCreditCard, FormatExpirationDate, capitalizeSentence, copyClipbord, formatCOPNumber, formatCOPNumberInput, formatDocument, formatPhoneNumber, getDigits, getDigitsRegister, getNavInfo, isAPhone, millisecondsToMinutesAndSeconds, swapArrayElements, validateName } from "../functions";
import _, { values } from 'lodash'
import QRCode from "react-qr-code";
import Loading from '../components/Loading';
import { Carousel } from "../components/Carousel";
import PinPayInfo from "../components/PinPayInfo";
import ListItem from "../components/ListItem";
import Modal from "../components/Modal";
import checkIcon from '../assets/icons/check-icon.png'
import SelectModal from "../components/SelectModal";
import moment from "moment";
import { countries_new } from "../constants";
import NavBarCobru from "../components/NavBarCobru";
import Input from "../components/Input";
import SplitAmount from "../components/SplitAmount";
import useRecaptcha from "../hooks/useRecaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { MainContext } from "../contexts/MainContext";
import { useNavigate, useLocation } from 'react-router-dom';

const getCuotasArray = () => {
    let cuotas = [{ id: 1, label: '1 Cuota', value: '1' }]
    for (let index = 1; index < 36; index++) {
        cuotas[index] = { id: index + 1, label: `${index + 1} Cuotas`, value: index + 1 };
    }
    return cuotas
}

const documentTypesArray = [
    { id: 0, label: 'Seleccionar', value: '' },
    {
        label: `CC - Cédula de ciudadanía`,
        label2: "CC",
        value: 0,
    },
    {
        label: `CE - Cédula de extranjería`,
        label2: "CE",
        value: 1,
    },
    {
        label: `TI - Tarjeta de identidad`,
        label2: "TI",
        value: 2,
    },
    {
        label: `NIT`,
        label2: "NIT",
        value: 3,
    }
]
const trascaribeTopUpAmpunts = [
    { id: 0, label: 'Monto a recargar', label2: 'Monto a recargar',value: '' },
    {
        label: `$10.000`,
        label2: "$10.000",
        value: 10000,
    },
    {
        label: `$20.000`,
        label2: "$20.000",
        value: 20000,
    },  {
        label: `$30.000`,
        label2: "$30.000",
        value: 30000,
    },  {
        label: `$40.000`,
        label2: "$40.000",
        value: 40000,
    },  {
        label: `$50.000`,
        label2: "$50.000",
        value: 50000,
    },  {
        label: `$60.000`,
        label2: "$60.000",
        value: 60000,
    },  {
        label: `$70.000`,
        label2: "$70.000",
        value: 70000,
    },  {
        label: `$80.000`,
        label2: "$80.000",
        value: 80000,
    },  {
        label: `$90.000`,
        label2: "$90.000",
        value: 90000,
    },  {
        label: `$100.000`,
        label2: "$100.000",
        value: 100000,
    },  {
        label: `Otro valor`,
        label2: `Otro valor`,
        value: 'otro',
    },
]
function Home(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const { methods, selectedMethod, cobruURL, cobru, params, isAPhoneNumber } = location?.state
    console.log(location?.state);
    const [activated, setActivate] = useState(false)
    const [paymentsMethod, setPaymentsMethod] = useState(methods || null)
    const [payMethodName, setPayMethodName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [paymentsMethodArray, setPaymentsMethodArray] = useState([])
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null)
    const [showCobru, setShowCobru] = useState(true)
    const [formValues, setFormValues] = useState({
        name: '',
        docType: '',
        doc: '',
        email: '',
        phone: '',
        description: ''
        //amount: ''
    })
    const [errorMessages, setErrorMessages] = useState({
        amount: '',
        name: '',
        docType: '',
        doc: '',
        email: '',
        phone: '',
        description: ''
        //amount: ''
    })
    /*     const [formValues, setFormValues] = useState({
            name: 'Angel Rodriguez',
            docType: '1',
            doc: '1001855422',
            email: 'angel@prueba.com',
            phone: '3160497786'
        }) */
    const [creditCardNumber, setCreditCardNumber] = useState('')
    const [OTPNumber, setOTPNumber] = useState('')
    const [creditCardDate, setCreditCardDate] = useState('')
    const [creditCardCVV, setCreditCardCVV] = useState('')
    const [creditCardCuotas, setCreditCardCuotas] = useState({ id: 1, label: '1 Cuota', value: '1' })
    let cuotas = getCuotasArray()
    const [expDate, setExpDate] = useState('')
    const [showForm, setShowForm] = useState(true)
    const [showQr, setShowQr] = useState(false)
    const [successCheck, setSuccessCheck] = useState(false)
    const [showPin, setShowPin] = useState(false)
    const [currencyWallet, setCurrencyWallet] = useState('')

    const [qrValue, setQrValue] = useState(null)
    const [waitingNequiPay, setWaitingNequiPay] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState({})
    const [paymentDetailsCobru, setPaymentDetailsCobru] = useState({})

    const [payPin, setPayPin] = useState({ code: '', ref: '' })
    const [countDown, setCountDown] = useState('00:00')
    const [countDowDaviplata, setCountDownDaviplata] = useState('00:00')
    const [runTimerCheck, setRunTimerCheck] = useState(null)
    const [runTimerCheckDaviplata, setRunTimerCheckDaviplata] = useState(null)
    const [currencyAmount, setCurrencyAmount] = useState('Calculando...')
    const [cryptoInterval, setCryptoInterval] = useState(null)
    const [daviplataInterval, setDaviplataInterval] = useState(null)
    const [bankList, setBankList] = useState([])
    const [amount, setAmount] = useState('')
    const [bankPse, setBankPse] = useState({ value: '', label: 'Seleccionar Banco' })
    const [showModal, setShowModal] = useState(false)
    const [documentTypes, setDocumentTypes] = useState(documentTypesArray)
    const [documentItem, setDocumentItem] = useState(documentTypesArray[1])
    const [errorMessage, setErrorMessage] = useState('')
    const [buttonName, setButtonName] = useState('')
    const [country, setCountry] = useState(
        { id: 0, label: 'Seleccionar', value: '', label2: '' },
    )
    const [transcaribeTopUpItem, seTranscaribeTopUpItem] = useState(
        { id: 0, label: 'Monto a recargar',label2: 'Monto a recargar',value: '' },
    )
    const [item_, setItem_] = useState(null)
    const [showOTP, setShowOTP] = useState(false)
    //const [showOTPDaviplata, setShowOTPDaviplata] = useState(false)
    const [cobruItem, setCobruItem] = useState(false)
    const [isPhoneNumber, setIsPhoneNumber] = useState(false)
    const [cobruURL_, setCobruURL_] = useState('')
    const [daviplataInfo, setDaviplataInfo] = useState(null)
    const [trm, setTrm] = useState(null)
    const [isInt, setIsInt] = useState(false)
    const [description, setDescription] = useState('')
    const [amountOptions, setAmountOptions] = useState([
        {label: 'COP', value: 'COP'},
        {label: 'USD', value: 'USD'},
    ])
    //const [amountOption, setAmountOption] = useState('COP')
    //const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    //const [showRecaptcha, setShowRecaptcha] = useState(false)

    /*  const [documentItem, setDocumentItem] = useState(
         { id: 0, label: 'Seleccionar', value: '' },
 
     ) */

     const [loadingIpInfo, setLoadingIpInfo] = useState(false)
     const { recaptchaValue } = useRecaptcha();
     const { isCaptchaValid, setIsCaptchaValid, showRecaptcha, setShowRecaptcha, globalCurrency, setGlobalCurrency
     } = useContext(MainContext);
     const handleRecaptchaChange = (response) => {
        // Esta función se llama cuando el reCAPTCHA se completa
        // Puedes validar el reCAPTCHA aquí y actualizar el estado
        // para indicar si es válido o no
        //console.log(response);
        setIsCaptchaValid(!!response);
      }
     const onSubmitRecaptchaValue = async () => {
        if (isCaptchaValid) {
            return true
          }
       //setShowRecaptcha(false)
       if (recaptchaValue) {
         const res = await axios.post('/verificar-recaptcha', { recaptchaToken: recaptchaValue });
         if (res?.status == 200 && res?.data) {
           if (res?.data?.success === true) {
             setIsCaptchaValid(true)
           }
           return res?.data?.success
         } else {
           setIsCaptchaValid(false)
           return false
         }
         //console.log('recaptchaValue', res);
         // Realiza la acción que necesites aquí, como enviar la solicitud al servidor.
         console.log("Puntuación de reCAPTCHA:", recaptchaValue);
       } else {
         setIsCaptchaValid(false)
         return false
         setShowRecaptcha(true)
         console.error("Por favor, completa la verificación reCAPTCHA.");
       }
     }
    //console.log('ACA LAS PROPS',location?.state);

    //ldqdt7ye
    useEffect(() => {
        if (!!cobruURL) {
            setCobruURL_(cobruURL)

        }
    }, [cobruURL])
    useEffect(() => {
        if (cobru) {
            setCobruItem(cobru)

        }
    }, [cobru])
    useEffect(() => {
        if (isAPhoneNumber) {
            setIsPhoneNumber(isAPhoneNumber)

        }
    }, [isAPhoneNumber])

    useEffect(() => {
        const res = countries_new.find(e => e.indicative === 57)
        setCountry(res)
    }, [])


    useEffect(() => {
        if (!!paymentsMethod) {
            formatPayment()

        }
    }, [paymentsMethod])

    useEffect(() => {
        if (!!params) {
            fillFields()
            fillUrl()
        }
    }, [params])

    useEffect(() => {
        if (paymentsMethodArray && currentPaymentMethod) {
            swapArrayElements_()
        }
    }, [paymentsMethodArray])

    useEffect(() => {
        try {
            if (!!currentPaymentMethod) {
                let name = Object.keys(currentPaymentMethod?.name)[0]
                setPayMethodName(name)
            }

        } catch (error) {
            console.log(error);
        }
    }, [currentPaymentMethod])

    useEffect(() => {
        getBankList()
    }, [])

    useEffect(() => {
        getTRM()
    }, [])
    
    useEffect(() => {
      if ((!!params && params?.int === 'true') && payMethodName === 'credit_card' && !!cobru) {
        setFormValues({
            name: '',
            docType: '',
            doc: '',
            email: '',
            phone: '',
            description: ''
            //amount: ''
        })
        console.log('acaxddddd');
        let arrayNew= [...documentTypesArray, {
            label: `PA - Pasaporte`,
            label2: "PA",
            value: 4,
        },
        {
            label: `PPT - Permiso de Protección Temporal`,
            label2: "PPT",
            value: 5,
        }]
        console.log('cobru',cobru?.owner_document_type);
        let item_ = null
        if (cobru) {
            //alert('OK')
            item_ = arrayNew.find(e => e.value.toString() === cobru?.owner_document_type.toString())
            console.log('item_: ', item_);
            setDocumentItem(item_)
        }
        setFormValues ({
            name: cobru?.alias || '',
            docType:item_?.value.toString() || '',
            //docType:item_?.id || '',
            doc:cobru?.owner_document || '',
            email: cobru?.owner_email || '',
            phone: cobru?.phone ? formatPhoneNumber(cobru?.phone.toString()) : '',
        })
        console.log('usee',{
            name: cobru?.alias || '',
            docType:item_?.value.toString() || '',
            doc:cobru?.owner_document || '',
            email: cobru?.owner_email || '',
            phone: cobru?.phone ? formatPhoneNumber(cobru?.phone.toString()) : '',
           
        });
      }
    }, [params, payMethodName])
    
    useEffect(() => {
        if (cryptoInterval) {
            clearInterval(cryptoInterval);
        }

        return () => {
            if (cryptoInterval) {
                clearInterval(cryptoInterval);
            }
        }
    }, [])
    useEffect(() => {
        if (daviplataInterval) {
            clearInterval(daviplataInterval);
        }
        //clear interval ()
        /* return () => {
            if (daviplataInterval) {
                clearInterval(daviplataInterval);
            }
        } */
    }, []) 

    //console.log('REACT_APP_RECAPTCHA_KEY: ',process.env.REACT_APP_RECAPTCHA_KEY);

    /* useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
        script.async = true;
        document.body.appendChild(script);
    }, []); */


    const formatPayment = () => {
        let array = []
        let paymentsMethod_ = []
        for (var key in paymentsMethod) {
            if (paymentsMethod.hasOwnProperty(key)) {
                if (paymentsMethod[key] === true /* && key !== "BTC" && key !== "DAI" && key !== "BCH" */) {
                    paymentsMethod_.push(key)
                }
            }
        }
        console.log(paymentsMethod_);
        /* Object.keys(paymentsMethod).map((item, i) => {
            array.push({ [item]: item === item || false, index: i, name: { [item]: item === item }, available:(item === item || false ) })
        }) */
        paymentsMethod_.map((item, i) => {
            array.push({ [item]: item === item || false, index: i, name: { [item]: item === item }, available: (item === item || false) })
        })
        //
        let item_ = {}

        array.map((item, i) => {
            if (item.name[selectedMethod]) {
                return item_ = item
            }

        })
        console.log('array: ', params);
        console.log('item_: ', item_);
        console.log('selectedMethod: ', Object.keys(selectedMethod));
       /*  if (!!params && params?.int === 'true') {

            if (array.length > 0 && item_) {
                array = array.filter(item => Object.keys(item.name)[0] === 'credit_card');
            }
            setPaymentsMethodArray(array)
            setCurrentPaymentMethod( array[0] || null)
            onChangeBTNName( array[0] || null)
            return
        }
         */
        if (array.length > 0 && item_) {
            swapArrayElements(array, array[0]?.index, item_?.index)
        }
        setPaymentsMethodArray(array)
        setCurrentPaymentMethod(item_ ? item_ : array[0] || null)
        console.log('setPaymentsMethodArray', array);
        onChangeBTNName(item_ ? item_ : array[0] || null)

    }

    const swapArrayElements_ = async () => {

    }

    const validateMaxMinUSD =async (amount_='') => {
        let amount_new =  amount_ ? amount_ : amount ? amount : ''
        console.log(amount_new);
        if (globalCurrency === 'USD' || (!!params && params?.currency?.toString()?.toLowerCase() === 'usd')) {
            //alert('ok')
           /*  console.log(getDigitsRegister(amount_new));
            console.log( (parseFloat(getDigitsRegister(amount_new)) * parseFloat(trm))/parseFloat(trm));
            console.log( cobru?.max /trm);
            console.log(parseFloat(getDigitsRegister(amount_new)) / parseFloat(trm)); */
            let trmNew = null 
            if (!trm) {
                const res = await axios.post('/getSwapMoney', { amount: '10000' });
                if (res?.data?.data && res?.status === 200) {
                    trmNew = res?.data?.data?.price
                }
            }
            
            const TRM  =  trmNew ? trmNew : trm ?  trm : ''
            const amountTrmUsd = parseFloat(getDigitsRegister(amount_new)) / parseFloat(trm)
            const cobruMaxUSD = parseInt(parseInt(cobru?.max) / parseInt(TRM))
            const cobruMinUSD = parseInt(parseInt(cobru?.min) /parseInt(TRM))
            console.log(cobruMaxUSD, cobruMinUSD);
            console.log(parseFloat(getDigitsRegister(amount_new)) < cobruMinUSD);
            console.log(parseFloat(getDigitsRegister(amount_new)) > cobruMaxUSD);
            
            if (parseFloat(getDigitsRegister(amount_new)) < cobruMinUSD) {
                setErrorMessages({ ...errorMessages, amount: 'El monto mínimo es ' + formatUSD(cobruMinUSD.toString(), true) + ' USD'})
                return false
            }
            if (parseFloat(getDigitsRegister(amount_new)) > cobruMaxUSD) {
    
                setErrorMessages({ ...errorMessages, amount: 'El monto máximo es ' + formatUSD(cobruMaxUSD.toString(), true)+ ' USD' })
                return false
            }
            if (parseFloat(getDigitsRegister(amount_new)) <= cobruMaxUSD && getDigitsRegister(amount) >=cobruMinUSD) {
                setErrorMessages({ ...errorMessages, amount: '' })
                return true
            }
            return
            /* console.log('OK', (getDigitsRegister(amount) * trm),cobru?.min);
            if ((getDigitsRegister(amount) * trm) < cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: 'El monto minimo es de ' + formatCOPNumber(cobru?.min) })
                return false
            }
            if ((getDigitsRegister(amount) * trm) > cobru?.max) {

                setErrorMessages({ ...errorMessages, amount: 'El monto maximo es de ' + formatCOPNumber(cobru?.max) })
                return false
            }
            if ((getDigitsRegister(amount) * trm) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: '' })
                return true
            }
            return */
        } 
    }

    const validateMaxMinCop = (amount_='') => {
            let amount_new = amount_ ? amount_ : amount_? amount : ''
             console.log('amount_new: ',amount_new);
            if (getDigitsRegister(amount_new) < cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: 'El monto mínimo es ' + formatCOPNumber(cobru?.min)+ ' COP' })
                return false
            }
            if (getDigitsRegister(amount_new) > cobru?.max) {
    
                setErrorMessages({ ...errorMessages, amount: 'El monto máximo es ' + formatCOPNumber(cobru?.max)+ ' COP' })
                return false
            }
            if (getDigitsRegister(amount_new) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                setErrorMessages({ ...errorMessages, amount: '' })
                return true
            }
    }
    

    const getTRM = async () => {
        try {
            const res = await axios.post('/getSwapMoney', { amount: '10000' });
            if (res?.data?.data && res?.status === 200) {
                setTrm(res?.data?.data?.price)
            }
            //console.log('getSwapMoney: ',res);
        } catch (error) {
            console.log(error);
        }
    }
    const fillFields = async () => {
        console.log('fillFields: ',params);
        //console.log(params?.currency.toString().toLowerCase() === 'usd');
        let item_ = null
        if (params?.doc_type) {
            item_ = documentTypes.find(e => e.value.toString() === params?.doc_type.toString())
            console.log('item_: ', item_);
            setDocumentItem(item_)
        }
        
        if (params?.currency) {
            console.log('currency', params?.currency.toString().toLowerCase() === 'usd' ? 'USD' :'COP');
            setGlobalCurrency(params?.currency.toString().toLowerCase() === 'usd' ? 'USD' :'COP')
        }
        if (params?.title) {
           setDescription(params?.title) 
        }
        if (params?.amount) {
            if ((params?.int  && !params?.currency) ) {
                console.log('entro aca amount');

                let trmNew = null 
                if (!trm) {
                    const res = await axios.post('/getSwapMoney', { amount: '10000' });
                    if (res?.data?.data && res?.status === 200) {
                        trmNew = res?.data?.data?.price
                    }
                }
            
                const TRM  =  trmNew ? trmNew : trm ?  trm : ''
                setAmount(formatCOPNumberInput(params?.amount * TRM));
                
            }else if (globalCurrency === 'COP' || (params?.currency.toString().toLowerCase() === 'cop') || !params?.int || !params?.currency) {
                setAmount(formatCOPNumberInput(params?.amount));
            } else  {
                formatUSD(params?.amount)
                
            } 
            
            validateMaxMinUSD(params?.amount)

        }
        if (params?.int === 'true') {
            console.log('okkkk');
            setIsInt(true)
            setGlobalCurrency('USD')
            if (!!params && params?.int === 'true') {
                let amountText= ''
                let amount_= params?.amount ? params?.amount: amount ? getDigitsRegister(amount): ''
                if (globalCurrency === 'COP') {
                    amountText =(formatCOPNumberInput(amount_));
                    
                } else {
                    amountText = formatUSD(amount_)

                }
                setTimeout(() => {
                    
                    onChangeBTNName({credit_card: true, index: 7, available: true}, amountText+ ' USD')
                }, 500);
            }
            if (params?.amount ) {
                
                formatUSD(params?.amount || '')
            }

        }
/* 
        Object.keys(params).forEach(element => {
            if (element === 'int') {
                setIsInt(true)
                return
            }
        }); */
        
        setFormValues({
            email: params?.email,
            name: params?.name,
            phone: params?.phone ? formatPhoneNumber(params?.phone) : '',
            doc: params?.doc_number ? formatDocument(params?.doc_number) : '',
            docType: item_?.value || ''
        })
        if (item_?.id) {
            let item = documentTypes.find(e => e.value === item_?.id)
            setDocumentItem(item)
        }
    }

    const getBankList = async () => {
        try {
            const res = await axios.post('/api/getBankList')
            if (res.data.status === 200) {
                let banks = res.data.data
                banks = banks.map(bank => {
                    let name = capitalizeSentence(bank.bankName)
                    return ({ label: name, value: bank.bankCode })
                })
                banks = [{ value: '', label: 'Seleccionar Banco' }, ...banks]
                //console.log('Bancos', banks);
                setBankList(banks)
            }
        } catch (error) {
            setErrorMessage('Hubo un problema obteniendo la lista de bancos')

        }
    }

    const getCobruInfo = async () => {
        try {
            const res = await axios.post('/api/getCobruDetails', { cobruURL: cobruURL })
            console.log('res getCobruInfo', res);
            
            if (res.data && res?.status === 200) {
                setPaymentsMethod(res.data.data.payment_methods_configuration)
                setShowCobru(true)
            } else if (res?.data === '' && res?.status === 200) {
                setShowCobru(false)
            } else {
                setShowCobru(false)

            }
        } catch (error) {
            console.log('res error: ', error);
            setShowCobru(false)
        }
    }

    const resetData = () => {

        setErrorMessage('')
        setRunTimerCheckDaviplata(false)
        setRunTimerCheck(false)
        setCryptoInterval(null)
        setDaviplataInterval(null)
        setErrorMessages({
            amount: '',
            name: '',
            docType: '',
            doc: '',
            email: '',
            phone: '',
            description: ''
        })
        setLoading(false)
        setOTPNumber('')
        setShowOTP(false)
        setRunTimerCheckDaviplata(false)
        setCountDownDaviplata('')
        clearInterval(daviplataInterval)
        clearInterval(cryptoInterval)
        setDaviplataInfo(null)
        setSuccessCheck(false)
        //setIsInt(false)
    }

    const addParms = async (value='') => {
        window.history.replaceState(null, null, `?url=${value}`);
    }

    const fillUrl = async () => { 
        // Obtén la URL actual
        let baseUrl = '';
        let currentUrl = window.location.href;
        let params_ = new URLSearchParams(params);
        console.log('cobruURL', cobruURL);
        let newCobruUrl = cobruURL ? `/${cobruURL}` : ""
        let finalUrl = baseUrl + /* newCobruUrl+'?' + */ params_.toString();
        // Reemplaza "home" con tus nuevos parámetros
        //console.log(finalUrl);
        let newUrl = currentUrl.replace("/home", finalUrl);
        // Reemplaza el estado actual en el historial
        window.history.replaceState(null, null, newUrl);
    }

    const pay = async (push = true) => {
        try {

            resetData()
            //Validaciones:
            if (!validateInput().all(true)) {
                return
            }
            setLoading(true)
            const res = await onSubmitRecaptchaValue()
            console.log('onSubmitRecaptchaValue: ', res);
            if (res) {
                setShowRecaptcha(false)
            } else {
                setLoading(false)
                setShowRecaptcha(true)
                return
            }
            if (isCaptchaValid || res) {

                let url = null
                //creacion del cobru 
                if (isPhoneNumber /* && !url */) {
                    const res = await axios.post('/cobru', {
                        amount: globalCurrency === 'USD' ? ((getDigitsRegister(amount) * trm)) : getDigits(amount),
                        phone: cobruURL,
                        description: description || ''
                    })
                    console.log('res cobru: ', res);
                    if (res?.status === 200 && res?.data?.data?.result === 'OK') {
                        setCobruURL_(res?.data?.data?.url)
                        url = res?.data?.data?.url
                        //lksdsd
                        addParms(url)
                    } else {
                        setLoading(false)
                        setErrorMessage('No se pudo crear el cobru')
                        return
                    }

                }


                let payload = {
                    "name": formValues.name,
                    "payment": payMethodName,
                    "cc": formValues.doc ? getDigitsRegister(formValues.doc) : documentItem.value ? getDigitsRegister(documentItem.value) : '',
                    "email": formValues.email,
                    "phone": formValues.phone.replace(/\D/g, ""),
                    "document_type": documentItem.value,
                    payer_country_code: country ? `+${country?.indicative}` : ''
                }
                console.log('payload:', payload);
                console.log('formValues:', formValues);


                /* const payMethodName = Object.keys(currentPaymentMethod.name)[0] */
                if (!!params && params?.callback_url) {
                    payload.callback = params?.callback_url

                }
                if (!!params && params?.redirect_url) {
                    payload.payer_redirect_url = params?.redirect_url

                }
                if (payMethodName === 'NEQUI') {
                    payload.phone_nequi = formValues.phone.replace(/\D/g, "")
                    payload.push = push
                }
                if (payMethodName === 'pse') {
                    payload.bank = bankPse.value
                    if (!!params && params?.redirect_url) {
                        payload.payer_redirect_url = params?.redirect_url
                    }
                }

                if (payMethodName == 'BTC') {

                }
                /* if (payMethodName === "daviplata") {
                    payload.cc = '996828'
                } */
                if (payMethodName === 'credit_card') {
                    payload.credit_card = creditCardNumber.replace(/\D/g, "")
                    payload.expiration_date = creditCardDate
                    payload.cvv = creditCardCVV
                    payload.dues = parseInt(creditCardCuotas.value)
                }
                console.log('payload: ', payload);
                const resp = await axios.post('/api/getDetailsPay', { cobruURL: isPhoneNumber ? url : cobruURL_, payload })
                console.log('Respuesta directa', resp);
                if (resp.data.status === 200) {
                    let str = JSON.stringify(resp?.data?.data)
                    console.log(resp.data.data);
                    let dataRes = JSON.parse(resp?.data?.data)
                    const paymentDetails = dataRes ? dataRes[0].fields : false
                    const paymentDetailsCobru = dataRes ? dataRes[1].fields : false
                    JSON.parse(str)

                    console.log('data transformado', dataRes);
                    console.log('paymentDetails', paymentDetails);
                    //console.log('str', );
                    if (payMethodName === 'NEQUI') {
                        if (push) {
                            setShowForm(false)
                            setWaitingNequiPay(true)
                            setLoading(false)

                        } else {
                            setWaitingNequiPay(false)

                            console.log(dataRes);
                            setShowForm(false)
                            setQrValue(dataRes[0].fields.ref)
                            setShowQr(true)
                            setLoading(false)

                        }
                    }

                    if (payMethodName === 'dale') {
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                    }
                    if (payMethodName === 'corresponsal_bancolombia') {
                        setPayPin({ code: dataRes[0]?.fields?.project_code, ref: dataRes[0]?.fields?.ref })
                        setShowForm(false)
                        setShowPin(true)
                        setLoading(false)

                        console.log(dataRes);
                    }
                    if (payMethodName === 'efecty') {
                        const date = dataRes[1]?.fields?.expiration_date;

                        const fecha = new Date(date);

                        const newDateFormat = fecha.toISOString().replace(/-/g, "/").slice(0, 10);

                        setPayPin({ code: dataRes[0]?.fields?.project_code, ref: dataRes[0]?.fields?.ref })
                        setShowForm(false)
                        setShowPin(true)
                        setExpDate(newDateFormat)
                        setLoading(false)

                        console.log(dataRes);
                    }
                    if (payMethodName === 'pse') {
                        //console.log('llega aca');
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                    }
                    if (payMethodName === 'tpaga') {
                        //console.log('llega aca');
                        console.log(dataRes);
                        window.location.href = `${dataRes[0]?.fields?.checkout}`;
                        setLoading(false)

                    }
                    if (payMethodName === 'BTC') {
                        setLoading(false)

                    }
                    if (payMethodName === 'USDT') {
                        setPaymentDetails(paymentDetails)
                        setRunTimerCheck(true)
                        subtractTimeReload(paymentDetails?.checkout.toString())
                        setShowForm(false)
                        setQrValue(paymentDetails.qr_bitcoin)
                        setCurrencyWallet(paymentDetails.url)
                        setShowQr(true)
                        setLoading(false)

                    }
                    if (payMethodName === "credit_card") {
                        setPaymentDetails(paymentDetails)
                        setPaymentDetailsCobru(paymentDetailsCobru)
                        setShowForm(false)
                        setSuccessCheck(true)
                        setLoading(false)

                    }
                    if (payMethodName === "daviplata") {
                        //setShowForm(false)
                        setPaymentDetails(paymentDetails)
                        setPaymentDetailsCobru(paymentDetailsCobru)
                        setRunTimerCheckDaviplata(true)
                        subtractTimeDaviplata(120)
                        setShowOTP(true)
                        setLoading(false)

                    }
                    setLoading(false)

                }
                if (resp.data.data.error) {
                    if (resp.data.data.error === "Payment method is not enabled.") setErrorMessage('El método de pago no está habilitado')
                    else setErrorMessage(resp.data.data.error)


                } else if (resp?.data?.status === 400) {


                    setErrorMessage('Hubo un problema inesperado')
                    setLoading(false)

                }
                console.log('que devuelve getDetailsPay', resp);
                /*         setQrValue(resp)
                setShowForm(false)
                setShowQr(true) */
                setLoading(false)

            }
        } catch (error) {
            console.log('error pay', error);
            setErrorMessage('Hubo un problema inesperado')

        }
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        const formatValue = formatCOPNumber(value);
        setAmount(formatValue);
    }


    const subtractTimeReload = async (time) => {
        console.log('time: ', time);
        let count = (parseInt(time) * 1000);

        //let count = 1200000;
        const newTimer = setInterval(async () => {
            count -= 1000;
            const newMin = await millisecondsToMinutesAndSeconds(count)
            const { minutes, seconds } = newMin
            /* console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes));
            console.log(count); */
            console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes))
            setCountDown(minutes + ":" + (seconds < 10 ? '0' : '') + seconds);
            console.log('runTimerCheck', runTimerCheck);
            if (count <= 0 || parseInt(minutes) <= 0 || runTimerCheck === false) {
                console.log('clearInterval:');
                clearInterval(newTimer);
                setPaymentDetails({})
                setCountDown('')
                setRunTimerCheck(false)
                setShowQr(false)

                //getOpt('SMS');
            } else if ((parseInt(seconds) % 5) === 0) {
                //console.log('5 segussss');
                setPaymentDetails(prevState => {
                    getCryptoStatusReload(prevState)
                    return prevState
                })
            }

        }, 1000);
        setCryptoInterval(newTimer);
    };

    const subtractTimeDaviplata = async (time) => {
        console.log('time: ', time);
        let count = (parseInt(time) * 1000);

        //let count = 1200000;
        const newTimer = setInterval(async () => {
            count -= 1000;
            const newMin = await millisecondsToMinutesAndSeconds(count)
            const { minutes, seconds } = newMin
            /* console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes));
            console.log(count); */
            console.log('seconds', parseInt(seconds));
            console.log('minutes', parseInt(minutes))
            setCountDownDaviplata(minutes + ":" + (seconds < 10 ? '0' : '') + seconds);
            console.log('runTimerCheck', runTimerCheckDaviplata);
            if (count <= 0 || parseInt(minutes) <= 0 || runTimerCheckDaviplata === false) {
                console.log('clearInterval:');
                setRunTimerCheckDaviplata(false)
                setCountDownDaviplata('')
                clearInterval(newTimer)
                //getOpt('SMS');
            } /* else if ((parseInt(seconds) % 5) === 0) {
                console.log('5 segundos');
                setRunTimerCheckDaviplata(false)
                setCountDownDaviplata('')


                
            } */

        }, 1000);
        setDaviplataInterval(newTimer);
    };

  /*   useEffect(() => {
        console.log('showQr', showQr);
        console.log('qrValue', qrValue);
    }, [showQr, qrValue]) */

    const getCryptoStatusReload = async (paymentDetails) => {
        /* let status = await API.GET.GetCryptoTransactionStatus(paymentDetails.GOrdenId, paymentDetails.method); */
        try {
            let status = await axios.post('/crypto_status', { transactionID: paymentDetails.GOrdenId, method: paymentDetails.method });
            status = status?.data?.data
            console.log('status: ', status, paymentDetails);
            if (status?.state == "approved") {
                setRunTimerCheck(false)
                setShowQr(false)
                setPaymentDetails({})
                setSuccessCheck(true)
                clearInterval(cryptoInterval)
            }
        } catch (error) {
            console.log(error)

        }

    }
    const GetAvaliableCriptoValues = async () => {
        try {
            console.log('GetAvaliableCriptoValues', AvaliableCriptoMethod());
            console.log('GetAvaliableCriptoValues', getDigits(amount));
            if (AvaliableCriptoMethod() && (cobru?.amount || getDigits(amount))) {
                let currenciesCripto = await axios.post('/crypto_currencies_available', { amount: cobru?.amount || getDigits(amount), currency:payMethodName })
                console.log('res de currenciesCripto:', currenciesCripto)
                let arrayCryptos = currenciesCripto?.data?.data
                if (arrayCryptos) {
                    arrayCryptos.forEach(item => {
                        if (item.currency == payMethodName) {
                            //alert('ok')
                            setCurrencyAmount(item.amount)
                        }
                    });
                }
                console.log("CURRIENCIES CRIPTO:" + currencyAmount);
            }
        } catch (error) {
            setErrorMessage('Hubo un problema obteniendo el valor crypto')
            console.log('GetAvaliableCriptoValues', error);
        }

    }

    const getDaviplataStatus = async () => {
        try {
            setLoading(true)
            setErrorMessage('')
            if (!OTPNumber || !cobruURL_ || OTPNumber.length < 6) {
                console.log('invalid');
                setLoading(false)

                return
            }
            console.log({ url: cobruURL_, otp: OTPNumber });
            let status = await axios.post('/daviplata', { url: cobruURL_, otp: OTPNumber });
            if (status?.data?.status === 400) {
                setErrorMessage(status?.data?.data?.message)
                setRunTimerCheckDaviplata(false)
                clearInterval(daviplataInterval)
                setSuccessCheck(false)
                setLoading(false)

            } else if (status?.data?.data?.result == 'OK') {
                setRunTimerCheckDaviplata(false)
                setDaviplataInfo(status?.data?.data?.number_transaction)
                clearInterval(daviplataInterval)
                setShowForm(false)
                setSuccessCheck(true)
                setLoading(false)

            }
            setLoading(false)
            console.log('getDaviplataStatus: ', status);
        } catch (error) {
            console.log(error);
            setErrorMessage('error daviplata')
            setLoading(false)

        }
    }

    useEffect(() => {
        setErrorMessage('')
    }, [payMethodName, formValues, amount])

    useEffect(() => {
        GetAvaliableCriptoValues()
        if (payMethodName !== 'daviplata') {
            setDocumentTypes([...documentTypesArray, {
                label: `PA - Pasaporte`,
                label2: "PA",
                value: 4,
            },
            {
                label: `PPT - Permiso de Protección Temporal`,
                label2: "PPT",
                value: 5,
            }])
        } else {
            setDocumentItem(documentTypesArray[1])
            setDocumentTypes(documentTypesArray)
        }
    }, [payMethodName])
    useEffect(() => {
        if (!!buttonName && buttonName ===  'USDT') {
          //console.log('useEffect');
          GetAvaliableCriptoValues(cobru?.amount || getDigits(amount))
        }
      }, [buttonName])

      useEffect(() => {
        if (transcaribeTopUpItem?.value && (transcaribeTopUpItem?.value !== '' && transcaribeTopUpItem?.value !=="otro")) {
            console.log('transcaribeTopUpItem: ',transcaribeTopUpItem);
            setAmount(transcaribeTopUpItem?.value.toString())
        }
      }, [transcaribeTopUpItem])
      


    const getIndicatiosPin = () => {
        if (payMethodName === 'efecty') {
            return 'Menciona estos datos cuando estés en un punto Efecty'
        }
        if (payMethodName === 'corresponsal_bancolombia') {
            return 'Menciona estos datos cuando estés en un corresponsal Bancolombia'
        }
    }

    const AvaliableCriptoMethod = () => {
        console.log('payMethodName', payMethodName);
        return (
            payMethodName === 'BTC' ||
            payMethodName === 'BCH' ||
            payMethodName === 'DAI' ||
            payMethodName === 'DASH' ||
            payMethodName === 'USDT'
        );
    }
    const onChangeInput = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }
    const onChangeInputFormat = (e, formatPhone = false) => {
        if (formatPhone) {
            setFormValues({ ...formValues, [e.target.name]: formatPhoneNumber(e.target.value) })
            return
        }
        setFormValues({ ...formValues, [e.target.name]: formatDocument(e.target.value) })
    }

    const QueryIP = async (IP) => {

        try {
            setLoadingIpInfo(true)
            const URL = 'https://panel.validu.co'
            const x_api_key = '7oa71voJ.4gFO1Bul2aOS3zfpd9ARvIeeFofPQL0I'
            let headers= {
                Accept: 'application/json',
                'x-api-Key': x_api_key,
                "Content-Type": 'application/json',
                //'Authorization': "Bearer " + await GetAccessTokenValidu()
            }
            console.log('url QueryIP:',`${URL}/api/ip_validation/?ip=${IP}`);
            console.log('headers QueryIP:', headers);
            //return
            let response = await axios.get(`${URL}/api/ip_validation/?ip=${IP}`, { headers });                

            console.log('response QueryIP:',response?.data);
    
            if (response.status === 200 && response?.data?.data) {

               /*  setIpInfo(response?.data?.data?.data)
                console.log('HOLAL ',response?.data?.data?.data);
                setCity({
                    label :response?.data?.data?.data?.city || ''
                })
                setState({
                    label: response?.data?.data?.data?.regionName || ''
                }) */
                //return await response?.data;
                setLoadingIpInfo(false)
            }
    
        } catch (error) {
            console.error('QueryIP error: ',error.response?.data);
            if (error.response?.data) {
                setLoadingIpInfo(false)
                return error.response?.data

            }
            setLoadingIpInfo(false)
            return { "message": error, "error": true }

        }
    
    
    }

    const onChangeBTNName = (item, newLabelAmount='') => {
        //console.log('onChangeBTNName:',Object.keys(item)[0], item);
        setShowOTP(false)
        setCurrencyAmount('Calculando...')
        let name = 'Pagar';
        switch (Object.keys(item)[0]) {
            case 'credit_card':
                let amount_ = ''
                let amountLabel = ''
                /* if (!!newAmount) {
                    amount_=newAmount

                } else {
                    //const amount_ =  (!!params && params?.amount && params?.isInt === 'true' && params?.currency?.toString()?.toLowerCase() === 'usd') ? params?.amount : globalCurrency == 'COP'? amount ? amount : '0' : ''
                     amount_ =  (!!params && params?.amount) ? params?.amount :  amount ? amount : '0'
                     //amountLabel = (!!params && params?.currency?.toString()?.toLowerCase() === 'usd') ? formatUSD(amount_, true)+' USD' :formatCOPNumber(amount_, true)+ ' COP'

                }

                if (!!newAmount) {
                    amountLabel= amount_+ ' COP'
                } else {

                    amountLabel =(globalCurrency === 'USD' || (!!params && params?.currency.toString().toLowerCase() === 'usd')) ? formatUSD(amount_, true)+' USD' :formatCOPNumber(amount_, true)+ ' COP'
                } */
                if (!!newLabelAmount) {
                    amountLabel = newLabelAmount
                } else {
                    
                    amount_ =  (!!params && params?.amount) ? params?.amount :  amount ? amount : '0'
                    amountLabel = (!!params && params?.currency?.toString()?.toLowerCase() === 'usd') ? formatUSD(amount_, true)+' USD' :formatCOPNumber(amount_, true)+ ' COP'
                    console.log('onChangeBTNName globalCurrency: ', amount_);
                }
                name = 'Pagar '+ amountLabel;
                break;
            case 'NEQUI':
                name = 'Pagar con Nequi';
                break;
            case 'daviplata':
                name = 'Pagar con Daviplata';
                break;
            case 'corresponsal_bancolombia':
                name = 'Pagar con Corresponsal Bancolombia';
                break;
            case 'bancolombia_transfer':
                name = 'Pagar con Botón Bancolombia';
                break;
            case 'bancolombiapay':
                name = 'Pagar con Bancolombia pay';
                break;
            case 'pse':
                name = 'Pagar con PSE';
                break;
            case 'dale':
                name = 'Pagar con Dale';
                break;
            case 'efecty':
                name = 'Pagar con Efecty';
                break;
            case 'BTC':
                name = 'Pagar con BTC';
                break;
            case 'CUSD':
                name = 'Pagar con CUSD';
                break;
            case 'cobru':
                name = 'Pagar con Cobru';
                break;
            case 'bancolombia_qr':
                name = 'Pagar con QR Bancolombia';
                break;
            case 'USDT':
                name = 'Pagar con USDT';
                break;
            case 'DAI':
                name = 'Pagar con DAI';
                break;
            case 'baloto':
                name = 'Pagar con Baloto';
                break;
            case 'DASH':
                name = 'Pagar con DASH';
                break;
            case 'BCH':
                name = 'Pagar con BCH';
                break;
            case 'tpaga':
                name = 'Pagar con TPAGA';
                break;
            case 'credit_card_international':
                name = 'Pagar con Credit Card International';
                break;
            default:
                name = 'Pagar';
                break;
        }
        setButtonName(name)
        return name;
    }
    const validateInput = () => {
        try {

            const amountInput = () => {
                if (!amount) {
                    setErrorMessages({ ...errorMessages, amount: 'Debes elegir o ingresar un monto ' })
                    return false

                }
                if (getDigitsRegister(amount) < cobru?.min) {
                    setErrorMessages({ ...errorMessages, amount: 'El monto mínimo es de ' + formatCOPNumber(cobru?.min) })
                    return false

                }
                if (getDigitsRegister(amount) > cobru?.max) {

                    setErrorMessages({ ...errorMessages, amount: 'El monto máximo es de ' + formatCOPNumber(cobru?.max) })
                    return false
                }
                if (getDigitsRegister(amount) <= cobru?.max && getDigitsRegister(amount) >= cobru?.min) {
                    setErrorMessages({ ...errorMessages, amount: '' })
                    return true
                }
            }
            const nameInput = () => {
                if (!validateName(formValues?.name)) {
                    setErrorMessages({ ...errorMessages, name: 'Ingresa un nombre válido' })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, name: '' })
                    return true
                }
            }
            const descriptionInput = () => {
                if (!(formValues?.description)) {
                    setErrorMessages({ ...errorMessages, description: 'Ingresa un Número de tarjeta válido' })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, description: '' })
                    return true
                }
            }

            const docInput = () => {
                if (getDigitsRegister(formValues?.doc) < 6) {
                    setErrorMessages({ ...errorMessages, doc: 'Número de documento inválido' })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, doc: '' })

                    return true
                }
            }
            const emailInput = () => {
                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues?.email)) {
                    setErrorMessages({ ...errorMessages, email: 'Correo electrónico inválido' })
                    //setErrorMessages({ ...errorMessages, email: '¡Has ingresado una dirección de correo electrónico no válida!' })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, email: '' })
                    return true
                }
            }
            const phoneInput = () => {
                if (formValues.phone.replace(/\D/g, "").length < 6) {
                    setErrorMessages({ ...errorMessages, phone: 'Número de celular inválido' })
                    return false
                } else {
                    setErrorMessages({ ...errorMessages, phone: '' })
                    return true
                }
            }
            return {
                amountInput,
                nameInput,
                docInput,
                emailInput,
                phoneInput,
                descriptionInput,
                all: (withPhone = false) => {
                    let validations = nameInput() && docInput() && emailInput() && phoneInput() 
                    if (withPhone) {
                        validations = validations && amountInput() && descriptionInput()
                    }
                    return validations
                }
            }

        } catch (error) {
            console.log('error validate inputs', error);
        }
    }
    const validateDataBasic = () => { 
        /*         console.log('que es', formValues?.name);
                console.log('que es', payMethodName);
                console.log('que es', formValues?.doc);
                console.log('que es', formValues?.email);
                console.log('que es', formValues?.phone); */

        let disabled = false
        if (!formValues?.name || !payMethodName || !formValues?.doc || !formValues?.email || !formValues?.phone) {
            /* console.log('primera'); */

            disabled = true
        }
        if (payMethodName === 'pse' && bankPse.value === '') {
            /*  console.log('2'); */

            disabled = true
        }
        if (payMethodName === 'credit_card') {
            if (creditCardNumber.replace(/\D/g, "").length < 13 || creditCardDate.length < 5 || creditCardCVV.length < 3) {
                disabled = true
                /* console.log('3'); */
            }
        }
        if (isAPhone) {
            if (errorMessages.amount !== '') {
                disabled = true

            }

        }

        if (loading) {
            disabled = true

        }
        //console.log('que es disabled', disabled);
        return disabled
    }
    if (!showCobru) {
        return (
            <div className="flex justify-end items-end">
                <p className="text-center">{'El cobru no existe'}</p>
            </div>
        )
    }
    //console.log(cobru);

   
    const formatUSD = (value, isShow =false) => {
        console.log(value === '$');
            if (!value ||  value === '$') {
                setAmount('')
                return ''
            }
            // Elimina todos los caracteres que no sean dígitos o el punto decimal
            const cleanedValue = value.replace(/[^\d.]/g, '');
        
            // Divide el valor en dólares y centavos
            const [dollars, cents] = cleanedValue.split('.');
        
            // Formatea los dólares con comas
            const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        
            // Limita los centavos a dos dígitos
            const formattedCents = cents ? `.${cents.slice(0, 2)}` : '';
        
            // Combina los dólares y centavos formateados
            const formattedValue = `$${formattedDollars}${formattedCents}`;
            if (isShow) {
                return formattedValue
            }
            // Actualiza el estado con el valor formateado
            setAmount(formattedValue);
    };
    
    //console.log('isPhoneNumber: ',isPhoneNumber);
    const returnString = () => {
        const urlCompleta = cobru?.profile_picture;
        console.log(urlCompleta);
        const cadenaAContener = "https://s3.amazonaws.com/cobru-profile-pictures/";
        
        if (urlCompleta.includes(cadenaAContener)) {
            return cadenaAContener+urlCompleta
        } else {
            return urlCompleta
        }
    }

    return (
        <div className="flex h-screen items-center justify-start flex-col">
            {/* <NavBarCobru /> */}
            <div className="flex flex-col w-full">
                <div className="!h-10 w-full flex justify-center bg-main-transcaribe">
                    {/*   <img src="https://s3.amazonaws.com/cobru-profile-pictures/uploads/901237347-2022-09-22-32656.jpg" className="imagen"></img> */}
                    <div className="bg-transparent absolute flex imagenContainer !p-0">
                        {
                            cobru && cobru?.profile_picture ?
                                <img src={returnString()} className="imagen"></img>
                                :
                                <img src="https://s3.amazonaws.com/cobru-profile-pictures/uploads/901237347-2022-09-22-32656.jpg" className="imagen"></img>

                        }
                        {
                            cobru?.id &&
                            <div className="flex justify-end items-end">
                                <p className="text-left text-ref text-zinc-400">REF-{cobru?.id}</p>
                            </div>
                        }

                    </div>
                </div>
                <div className="bg-sec h-7 w-full">

                </div>
            </div>
            <div className="bg-main h-full w-full flex justify-center">
                <div className="flex flex-col justify-center items-center container-data">
                    <div className="flex flex-col justify-center items-start">

                        {/* <p className="bigBoldText">
                            {(!!params && params?.title) ?  params?.title : cobru ? cobru?.alias : ''}
                        </p> */}
                        {
                            cobru?.amount &&
                            <SplitAmount cobru={cobru} />

                           /*  <p className="price-tag">{`${cobru?.amount ? formatCOPNumber(cobru?.amount, true) : ''}`}</p> */
                        }
                        {
                            (!!params && params?.amount && (params?.int !== 'true' || !params?.int)) && 
                            <p className="price-tag">{`${params?.amount ? formatCOPNumberInput(params?.amount, true) : ''}`}</p>
                        }
                    </div>
                    <div className="flex justify-center flex-col items-center w-full ">
                        {
                            showForm
                                ?
                                <div className="min-h-full w-full">
                                    <p className="my-5 text-center">Datos de la recarga</p>
                                    {
                                        transcaribeTopUpItem && transcaribeTopUpItem?.value === 'otro' ?
                                        <div className="w-full">

                                                    <Input 
                                                    amountSelector={(payMethodName === 'credit_card' && (!!params && params?.int === 'true')) ?  true : false}
                                                    options={amountOptions}
                                                    optionValue={globalCurrency}
                                                    onChangeValue={async (e) => {
                                                        setGlobalCurrency(e.target.value)

                                                        if (params?.amount) {

                                                            setAmount('')
                                                        }
                                                        if (!!params && params?.int === 'true' && !params?.amount && amount && e?.target?.value === 'USD') {
                                                            //console.log('onChangeValue');
                                                            let amaount__ = amount
                                                            setAmount('')
                                                            let trmNew = null 
                                                            if (!trm) {
                                                                const res = await axios.post('/getSwapMoney', { amount: '10000' });
                                                                if (res?.data?.data && res?.status === 200) {
                                                                    trmNew = res?.data?.data?.price
                                                                }
                                                            }
                                                        
                                                            const TRM  =  trmNew ? trmNew : trm ?  trm : ''
                                                            const amount_new = parseFloat(getDigitsRegister((amaount__)))/ parseInt(TRM) 
                                                            console.log('amount_new: ',parseInt(amount_new));

                                                            setAmount(formatUSD(parseFloat(amount_new).toString(), true))
                                                            onChangeBTNName({credit_card: true, index: 7, available: true}, formatUSD(amount_new.toString(), true)+ ` ${e?.target?.value}`)

                                                            setTimeout(() => {
                                                                validateMaxMinUSD(amount_new)
                                                            }, 500);
                                                        }
                                                        if (!!params && params?.amount && e?.target?.value === 'USD') {
                                                            console.log('onChangeValue');

                                                            setAmount(formatUSD(params?.amount, true))
                                                            onChangeBTNName({credit_card: true, index: 7, available: true}, formatUSD(params?.amount, true)+ ` ${e?.target?.value}`)

                                                            setTimeout(() => {
                                                                validateMaxMinUSD(params?.amount)
                                                            }, 500);
                                                        }
                                                        if (!!params && params?.int === 'true' && !params?.amount && amount &&  e?.target?.value == 'COP') {
                                                            console.log('aca', amount);
                                                            let trmNew = null 
                                                            if (!trm) {
                                                                const res = await axios.post('/getSwapMoney', { amount: '10000' });
                                                                if (res?.data?.data && res?.status === 200) {
                                                                    trmNew = res?.data?.data?.price
                                                                }
                                                            }
                                                        
                                                            const TRM  =  trmNew ? trmNew : trm ?  trm : ''
                                                            console.log('TRM', TRM);
                                                            console.log('GETD', getDigitsRegister((amount)));
                                                            const amount_new = parseFloat(getDigitsRegister((amount)) *parseInt(TRM) )
                                                            console.log(amount_new);
                                                            setAmount(formatCOPNumberInput(parseInt(amount_new), true));
                                                            onChangeBTNName({credit_card: true, index: 7, available: true},formatCOPNumberInput(parseInt(amount_new), true)+ ` ${e?.target?.value}`)
                                                            setTimeout(() => {
                                                                validateMaxMinCop(parseInt(amount_new))
                                                            }, 500);
                                                           // setAmount(formatCOPNumberInput(getDigitsRegister(params?.amount) * parseFloat(trm), true));
                                                        }

                                                        if (trm && !!params && params?.amount && e?.target?.value == 'COP') {
                                                            const amount_new = parseFloat(getDigitsRegister(parseFloat(params?.amount)) *parseFloat(trm) )
                                                            console.log(amount_new);
                                                            setAmount(formatCOPNumberInput(parseInt(amount_new), true));
                                                            onChangeBTNName({credit_card: true, index: 7, available: true},formatCOPNumberInput(parseInt(amount_new), true)+ ` ${e?.target?.value}`)
                                                            setTimeout(() => {
                                                                validateMaxMinCop(parseInt(amount_new))
                                                            }, 500);
                                                           // setAmount(formatCOPNumberInput(getDigitsRegister(params?.amount) * parseFloat(trm), true));
                                                        }
                                                        //console.log();
                                                        //setAmount('')
                                                        setErrorMessages({
                                                            amount: '',
                                                            name: '',
                                                            docType: '',
                                                            doc: '',
                                                            email: '',
                                                            phone: '',
                                                            description: ''
                                                        })
                                                        //amount: '')
                                                        console.log('jeje aca');
                                                        setGlobalCurrency(e.target.value)
                                                    }}
                                                    value={amount} errorMessage={errorMessages.amount} placeholder="$0" onChange={(value) => {
                                                        if (globalCurrency === 'COP') {
                                                            setAmount(formatCOPNumberInput(value));
                                                            
                                                        } else {
                                                            formatUSD(value)

                                                        }
                                                    }}
                                                        onBlur={() => {
                                                            validateInput().amountInput()
                                                            GetAvaliableCriptoValues()
                                                            if (!!params && params?.int =='true' && payMethodName == 'credit_card') {    
                                                                    let amountText= ''
                                                                    let amount_= params?.amount ? params?.amount: amount ? getDigitsRegister(amount): ''
                                                                    if (globalCurrency === 'COP') {
                                                                        amountText =formatCOPNumberInput(amount_);
                                                                        console.log('amountText cop:', amountText);
                                                                    } else {
                                                                        amountText = formatUSD(amount_, true)
                                                                        console.log('amountText usd:', amountText);

                                                    
                                                                    }
                                                                    console.log(amount_, globalCurrency);
                                                                    setTimeout(() => {
                                                                        
                                                                        onChangeBTNName({credit_card: true, index: 7, available: true}, amountText+ ' '+globalCurrency)
                                                                    }, 500);
                                                                
                                                            }
                                                        }}
                                                        rate={trm}
                                                        className={(payMethodName !== 'credit_card'/*  && (!!params && params?.int === 'true') */)?  '!text-3xl font-semibold ': ""}
                                                        disabled={(!!params && params?.amount) ? true : false}
                                                    />
                                                    {/*  <input value={amount} onChange={(e) => {
                                                    
                                                }} className="payment-input h-14 text-4xl " placeholder="$0" /> */}
                                               
                                                </div>
                                        :
                                        <>
                                            <SelectModal  items={trascaribeTopUpAmpunts} selectedItem={transcaribeTopUpItem} setSelectItem={seTranscaribeTopUpItem} customLabelKey="label2" />
                                            {
                                            errorMessages?.amount !== ''
                                                ?
                                                <div className='w-full px-3'>
                                                    <p className="text-red-600 text-sm text-left">{errorMessages?.amount}</p>
                                                </div>
                                                :
                                                null
                                            }
                                        </>
                                    }

                                    <Input type='number' autocomplete="none" errorMessage={errorMessages.description} name="description" value={formValues.description} placeholder="Número de tarjeta Transcaribe" onChange={(value) => {
                                        setFormValues({ ...formValues, description: value })
                                        setDescription(value)
                                        }} onBlur={() => { validateInput().descriptionInput() }} />

                                    <p className="my-5 text-center">Escoge tu medio de pago</p>
                                    <Carousel paymentsMethodArray={paymentsMethodArray} paymentsMethod={paymentsMethod} currentPaymentMethod={currentPaymentMethod} onClickItem={(item) => {
                                        setCurrentPaymentMethod(item)
                                        onChangeBTNName(item)
                                    }} />
                                    {!showOTP && 
                                        <div className="flex items-center flex-col w-full mt-5">
                                            { AvaliableCriptoMethod() && currencyAmount &&
                                                <p className="price-tag my-4">{currencyAmount} {currencyAmount !== 'Calculando...' ? payMethodName : ''}</p>
                                            }
                                           
                                           
                                            {
                                                payMethodName === 'credit_card'
                                                    ?
                                                    <>
                                                        {
                                                            (!!params && !!params?.int === 'true') &&
                                                            <Input errorMessage={errorMessages.name} name="name" value={formValues.name} placeholder="Nombre completo" onChange={value => setFormValues({ ...formValues, name: value })} onBlur={() => { validateInput().nameInput() }} />
                                                        }

                                                        <Input autocomplete="cc-number" type="credit_card_number" name='creditCardNumber' placeholder="Número de la tarjeta" value={creditCardNumber} onChange={(value) => {
                                                            setCreditCardNumber(FormatCreditCard(value))
                                                        }} />
                                                        <div className="flex flex-row ">
                                                            <div className="w-2/3">

                                                                <Input type="number" className="payment-input" placeholder="MM/AA" name="creditCardDate" value={creditCardDate} onChange={(value) => {
                                                                    const inputValue = value;
                                                                    setCreditCardDate(FormatExpirationDate(inputValue))
                                                                }
                                                                } />
                                                            </div>
                                                            <div className="mr-3" />
                                                            <div className="w-2/3">

                                                                <input type="number" className="payment-input" maxLength={'3'} pattern="[0-9]*" placeholder="CVV" value={creditCardCVV} onChange={(e) => {
                                                                    const inputValue = e.target.value;
                                                                    const cleanedValue = inputValue.replace(/\D/g, '');

                                                                    if (inputValue.length < 4) {
                                                                        setCreditCardCVV(inputValue)
                                                                    }
                                                                }} />
                                                            </div>
                                                            <div className="mr-3" />
                                                            <SelectModal items={cuotas} selectedItem={creditCardCuotas} setSelectItem={setCreditCardCuotas} />

                                                        </div>

                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                (!!params && params?.int === 'true') && payMethodName === 'credit_card' ? 
                                                <>
                                                    <div className="flex flex-row w-full">
                                                            <div className={"w-4/6 mr-3"}>
                                                                <SelectModal items={countries_new} selectedItem={country} setSelectItem={setCountry} customLabelKey="label2" />
                                                            </div>
                                                            <div className="w-full">
                                                                <Input type="tel" name="phone" value={formValues.phone} onChange={(e) => { setFormValues({ ...formValues, phone: formatPhoneNumber(e) }) }} errorMessage={errorMessages.phone} placeholder="Número de celular" onBlur={() => validateInput().phoneInput()} />
                                                            </div>
                                                        </div>
                                                </>
                                                :
                                                <>
                                                    <Input errorMessage={errorMessages.name} name="name" value={formValues.name} placeholder="Nombre completo" onChange={value => setFormValues({ ...formValues, name: value })} onBlur={() => { validateInput().nameInput() }} />
                                                        <div className="flex items-start w-full" >

                                                            <div className="w-2/5 mr-3">
                                                                <SelectModal customLabelKey="label2" items={documentTypes} selectedItem={documentItem} setSelectItem={setDocumentItem} />
                                                            </div>
                                                            <div className="w-full">
                                                                <Input type="number" name="doc" errorMessage={errorMessages.doc} value={formValues.doc} onChange={(e) => {
                                                                    setFormValues({ ...formValues, doc: formatDocument(e) })
                                                                }} placeholder="Número de identificación"
                                                                    onBlur={() => { validateInput().docInput() }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Input name="email" value={formValues.email} onChange={(value) => setFormValues({ ...formValues, email: value })} onBlur={() => { validateInput().emailInput() }} errorMessage={errorMessages.email} placeholder="Correo electrónico" />
                                                        <div className="flex flex-row w-full">
                                                            <div className={"w-4/6 mr-3"}>
                                                                <SelectModal items={countries_new} selectedItem={country} setSelectItem={setCountry} customLabelKey="label2" />
                                                            </div>
                                                            <div className="w-full">
                                                                <Input name="phone" value={formValues.phone} onChange={(e) => { setFormValues({ ...formValues, phone: formatPhoneNumber(e) }) }} errorMessage={errorMessages.phone} placeholder="Número de celular" onBlur={() => validateInput().phoneInput()} />
                                                            </div>
                                                        </div>
                                                </>
                                            }
                                            {
                                                payMethodName === 'pse'
                                                    ?
                                                    <>
                                                        <SelectModal items={bankList} setSelectItem={setBankPse} selectedItem={bankPse} />

                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                showRecaptcha &&
                                                <div>
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY_v2}
                                                    onChange={handleRecaptchaChange}
                                                />
                                                </div>
                                            }
                                            <p className="text-red-600">{errorMessage}</p>
                                            <button disabled={validateDataBasic()} onClick={() => pay(true)} className={validateDataBasic() ? "btn-disabled" : "btn"}>{loading ? 'Cargando...' : buttonName}</button>
                                            {/* <button onClick={() => pay(true)} className="btn">{`Pagar con ${payMethodName}`}</button> */}
                                            <span className="text-xs text-zinc-400 font-normal mt-2">Pagos menores a $50.000 tienen un recargo de $1.500</span>

                                        </div>}
                                    {
                                        showOTP &&
                                        <div className="grid justify-items-stretch ">
                                            {
                                                runTimerCheckDaviplata && payMethodName === 'daviplata' /* && !showForm */
                                                    ?
                                                    <div className="bg-white px-4 py-1 rounded-2xl w-1/5 justify-self-center">
                                                        <p className="text-center">{countDowDaviplata}</p>
                                                    </div>
                                                    :
                                                    null

                                            }
                                            <Input name='OTP' placeholder="OTP" value={OTPNumber} onChange={(value) => {
                                                setOTPNumber(value)
                                            }} />
                                            <p className="text-red-600">{errorMessage}</p>
                                            <button disabled={(!countDowDaviplata || !runTimerCheckDaviplata || loading || (!OTPNumber || OTPNumber.length < 6))} onClick={() => getDaviplataStatus()} className={(!countDowDaviplata || !runTimerCheckDaviplata || loading || (!OTPNumber || OTPNumber.length < 6)) ? "btn-disabled" : "btn"}>{loading ? 'Cargando...' : buttonName}</button>
                                        </div>
                                    }
                                </div>

                                :
                                null
                        }
                        {
                            runTimerCheck && !showForm && payMethodName === 'USDT'
                                ?
                                <p className="price-tag my-4">{currencyAmount} {currencyAmount !== 'Calculando...' ? 'USDT' : ''}</p>
                                :
                                null
                        }
                        {
                            runTimerCheck && !showForm
                                ?
                                <div className="bg-white px-4 py-1 rounded-2xl">
                                    <p>{countDown}</p>
                                </div>
                                :
                                null

                        }


                        {
                            showQr && !showForm && payMethodName === 'NEQUI'
                                ?
                                <div className="flex flex-row items-center justify-center" >
                                    <p className="mr-1">Escanea con Nequi.</p>

                                </div>
                                :
                                null

                        }

                        {
                            showQr && !showForm
                                ?
                                <div className="my-5 h-full">
                                    <QRCode
                                        level="H"
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={qrValue}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                :
                                null
                        }
                        {
                            showQr && !showForm && payMethodName === 'USDT'
                                ?
                                <button className="flex flex-row items-center justify-center" onClick={() => copyClipbord(qrValue)}>
                                    <p className="mr-1">{qrValue?.substring(0, 14) + '...' + qrValue?.substring(qrValue.length - 3, qrValue?.length)}</p>
                                    <div className="bg-white p-2 mx-2 rounded-full">

                                        <RiFileCopy2Fill />
                                    </div>
                                </button>
                                :
                                null

                        }
                        {
                            waitingNequiPay && !showForm
                                ?
                                <div className="w-full h-full flex justify-center items-center flex-col py-5">
                                    <Loading />
                                    <p className="text-center">Revisa centro de notificación Nequi<br /> O
                                        <a
                                            className="underline cursor-pointer" onClick={() => {
                                                pay(false)
                                            }}>{' Paga con Qr'}
                                        </a>
                                    </p>

                                </div>
                                :
                                null
                        }
                        {
                            showPin && !showForm
                                ?
                                <>
                                    <PinPayInfo TitleText1={payMethodName === 'efecty' ? 'PROYECTO:' : "CONVENIO:"} text1={payPin.code} TitleText2={payMethodName === 'efecty' ? 'PIN:' : "REFERENCIA:"} text2={payPin.ref} exp={expDate} info={getIndicatiosPin()} />
                                </>
                                :
                                null
                        }
                        {
                            successCheck && !showForm
                                ?
                                <>
                                    <div className="my-2" />

                                    <p className="mt-1">REF-{paymentDetails?.ordenId}</p>
                                    <h2 className="text-3xl font-bold">Recarga exitosa</h2>
                                    <img src={checkIcon} className="w-24 my-6" />
                                    <div className="w-full border-gray-300 border bg-white p-4">
                                        {
                                            payMethodName === 'daviplata' && daviplataInfo &&
                                            <div className="w-full flex justify-between my-1 ">
                                                <p>Autorización</p>
                                                <p>{daviplataInfo}</p>
                                            </div>
                                        }
                                        <div className="w-full flex justify-between my-1 ">
                                            <p>Fecha y hora</p>
                                            <p>{moment().format('MM-DD-YYYY h:mm a')}</p>
                                        </div>
                                        <div className="w-full flex justify-between my-1 ">
                                            <p>Comisión</p>
                                            <p>{formatCOPNumber(parseInt(paymentDetailsCobru.fee_amount))}</p>
                                        </div>
                                        <div className="w-full flex justify-between my-1 ">
                                            <p>IVA Comisión</p>
                                            <p>{formatCOPNumber(parseInt(paymentDetailsCobru.fee_iva_amount))}</p>
                                        </div>
                                        <div className="w-full flex justify-between my-1 ">
                                            <p>Monto</p>
                                            <p>{formatCOPNumber(parseInt(paymentDetailsCobru.payed_amount))}</p>
                                        </div>
                                    </div>
                                    <p className="pt-1">{paymentDetails?.ip + ' ' + getNavInfo().browserName}</p>
                                    <div className="my-2" />

                                </>
                                :
                                null
                        }
                        <div className="m-3" />
                    </div>
                    <div className="h-14 w-full">

                        <hr />
                        <div className="first-child flex flex-row justify-between py-5 ">
                            <div className="flex flex-col justify-center">
                                <span className="text-xs text-zinc-400 font-normal">Más información</span>
                                <p className="font-bold text-lg text-neutral-600">
                                    {cobru ? cobru?.alias : ''}
                                </p>
                            </div>
                            <div className="flex flex-row items-center">
                                <div className="bg-white p-2 mx-2 rounded-full">
                                    <a href={cobru?.phone ? `tel:${cobru?.phone}` : ""}>
                                        <BsTelephoneFill />
                                    </a>
                                </div>
                                <div className="bg-white p-2 rounded-full">
                                    <a href={cobru?.owner_email ? `mailto:${cobru?.owner_email}` : ''}>
                                        <MdMail />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            {/* 
            <p className="text-left">URL</p>
                        {
                cobruURL &&
                <p className="text-left">{cobruURL}</p>
            }

            <p className="text-left">{showCobru.toString()}</p>
 */}
        </div >
    );
}

export default Home; 