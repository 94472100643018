import { useState, useEffect } from "react";
import axios from "axios";

const useRecaptcha = () => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    const initializeRecaptcha = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(`${process.env.REACT_APP_RECAPTCHA_KEY}`, { action: "submit" }).then((value) => {
            setRecaptchaValue(value);
          });
        });
      };
    };

    initializeRecaptcha();

  }, []);

  return { recaptchaValue };
};

export default useRecaptcha;
