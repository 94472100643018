import React from 'react'
import '../css/loadingCSS.css'

const Loading = (props) => {
    return (
        <div className='my-6 items-center justify-center flex'>

            <div className='lds-ring'><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loading