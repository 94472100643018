import React from 'react'

const PinPayInfo = ({ TitleText1 = 'CONVENIO:', text1 = '', TitleText2 = 'REFERENCIA:', text2 = '', info = 'Menciona estos datos cuando estés en un corresponsal Bancolombia', exp = '' }) => {
    return (
        <>
            <p className='text-center mt-3'>{info}</p>

            <div className="bg-white w-full p-4 my-5 rounded-2xl">
                <p className="font-bold text-base">{TitleText1}</p>
                <p className="font-bold text-4xl">{text1}</p>
                <p className="font-bold text-base mt-3">{TitleText2}</p>
                <p className="font-bold text-4xl">{text2}</p>
                <div className='flex items-center justify-center'>

                    <p className='warn mt-3'>
                        <span className='triangle'></span>

                        VÁLIDO HASTA {exp}
                    </p>
                </div>
            </div>
        </>
    )
}

export default PinPayInfo