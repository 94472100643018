import React from 'react'
import { formatCOPNumber, formatMoneySplit } from '../functions'

const SplitAmount = ({cobru = null}) => {
    return (
        <div className='flex flex-row'>
            <p className="price-tag p-0" style={{ paddingRight: 0 }} >{`${cobru?.amount ? formatMoneySplit(formatCOPNumber(cobru?.amount, true), '.')?.part1 : ''}`}</p>
            {
                formatMoneySplit(formatCOPNumber(cobru?.amount, true), '.')?.part2 &&
                <div className='price-tag flex items-center' style={{ paddingLeft: 0 }}>
                    <p className=" text-xs pt-1" >{`${cobru?.amount ? '.' + formatMoneySplit(formatCOPNumber(cobru?.amount, true), '.')?.part2 : ''}`}</p>
                </div>
            }
        </div>)
}


export default SplitAmount
