import React, { Fragment } from 'react'

import bancolombia from '../assets/bancolombia.png'
import pse from '../assets/pse.png'
import nequi from '../assets/nequi_card.png'
import credit_card from '../assets/credit_card_international.png'
import credit_card_international from '../assets/credit_card_international.png'

import efecty from '../assets/efecty.png'
import boton_bancolombia from '../assets/boton_bancolombia.png'
import dale from '../assets/dale.png'
import CUSD from '../assets/celo.png'
import bc_icon from '../assets/bc-card.png'
import corresponsal_bancolombia from '../assets/bancolombia_corresponsal.png'
import BTC from '../assets/bitcoin.png'
import cobru from '../assets/cobru_icon.png'
import bancolombia_qr_icon from '../assets/qr_bancolombia.png'
import bancolombia_transfer_icon from '../assets/bancolombia_transfer.png'
import usdt_icon from '../assets/usdt.png'
import baloto_icon from '../assets/baloto.png'
import DAI_icon from '../assets/dai.png'
import dash_icon from '../assets/dash.png'
import bch_icon from '../assets/bch.png'
import daviplata_icon from '../assets/daviplata.png'
import tpaga_icon from '../assets/tpaga.png'

export const Carousel = ({ paymentsMethodArray, onClickItem = () => { }, paymentsMethod, currentPaymentMethod }) => {
    const ImageBank = (id) => {
        let img = null;
        switch (id) {
            case 'credit_card':
                img = credit_card;
                break;
            case 'NEQUI':
                img = nequi;
                break;
            case 'corresponsal_bancolombia':
                img = corresponsal_bancolombia;
                break;
            case 'bancolombia_transfer':
                img = bancolombia_transfer_icon
                break;
            case 'bancolombiapay':
                img = bc_icon
                break;
            case 'pse':
                img = pse;
                break;
            case 'dale':
                img = dale;
                break;
            case 'efecty':
                img = efecty;
                break;
            case 'BTC':
                img = BTC;
                break;
            case 'CUSD':
                img = CUSD;
                break;
            case 'cobru':
                img = cobru;
                break;
            case 'bancolombia_qr':
                img = bancolombia_qr_icon
                break;
            case 'USDT':
                img = usdt_icon
                break;
            case 'DAI':
                img = DAI_icon;
                break;
            case 'baloto':
                img = baloto_icon;
                break;
            case 'DASH':
                img = dash_icon;
                break;
            case 'BCH':
                img = bch_icon;
                break;
            case 'credit_card_international':
                img = credit_card_international;
                break;
            case 'daviplata':
                img = daviplata_icon;
                break;
            case 'tpaga':
                img = tpaga_icon;
                break;
            default:
                img = null;
                break;
        }
        return img;
    };
    const getPadding = (method) => {
        switch (method) {
            case 'bancolombia_transfer':
            case 'corresponsal_bancolombia':
                return { padding: 0 }
                break;
            case 'DASH':
            case 'bancolombiapay':
                return { padding: 15 }
                break;
            default:
                return null
                break;
        }

    }
    return (
        <div className="paymentOptions">
            {paymentsMethod && paymentsMethodArray.map((item, i) => {

                return (
                    <Fragment key={`item-${i}`} >
                        <button /* key={`item-${i}`} */ onClick={() => onClickItem(item)} className={`bg-white flex items-center justify-center paymentOptionsx ${item === currentPaymentMethod ? 'paymentOptionBorder' : ''}`} style={getPadding(Object.keys(item)[0])} >
                            <img className={`${item?.name?.credit_card ? 'imagen-data_' : ''} imagen-data`} alt={Object.keys(item)[0]} src={ImageBank(Object.keys(item)[0])} />
                        </button>
                        {
                        }
                    </Fragment>
                )
            })
            }

        </div>
    )
}
