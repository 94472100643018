import React from 'react'
import '../css/touchableCSS.css'


const PaymentTouchable = ({ label = '', onClick = () => { }, className = '', imgArray = [], imgClassname = '' }) => {
    return (
        <div onClick={() => onClick()} className={`py-3 px-3 touchable flex items-center justify-center h-20 cursor-pointer ${className}`}>
            {label &&
                <div>
                    <p>{label}</p>
                </div>
            }
            {
                imgArray && imgArray.map((element, i) => {
                    return (
                        <div key={i} className={`flex flex-row items-center`}>
                            <img key={i} src={element.img} className={` ${imgClassname} ${element?.className || ''}`} alt='img' ></img>
                        </div>
                    )
                })

            }
        </div>
    )
}

export default PaymentTouchable