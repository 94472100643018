// 1. Crear el contexto
import React, { createContext, useContext, useState } from 'react';

export const MainContext = createContext();
// 2. Crear el proveedor del contexto
export const MainProvider = ({ children }) => {
    const [isCaptchaValid, setIsCaptchaValid] = useState(!false);
    const [showRecaptcha, setShowRecaptcha] = useState( false)
    const [globalCurrency, setGlobalCurrency] = useState('COP')
    //valores
    const value= {
        isCaptchaValid, setIsCaptchaValid, showRecaptcha, setShowRecaptcha,
        globalCurrency, setGlobalCurrency
    }
    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
    )

}
