import './App.css';
import Home from './page/Home'
import NotFound from './page/NotFound';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Intro from './page/Intro';
import { MainProvider } from './contexts/MainContext';


function App() {
  return (
    <div className='h-screen'>
      <MainProvider>
        <BrowserRouter>
          <Routes>
            <Route index path='/' element={<Intro />}/>
            {/* <Route index path=':cobruURL' element={<Intro />}/> */}
            <Route path='/home' element={<Home />}/>
            <Route path='*' element={<NotFound />}/>
          </Routes>
        </BrowserRouter>
      </MainProvider>
    </div>


  );
}

export default App;
